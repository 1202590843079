import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Volumenfiller = ({ data, location, ...props }) => {
	return (
		<Layout location={location} title="Volumenfiller" noSlider {...props}>
			<p>
				Im Laufe unserer Lebensjahre reduzieren sich die kleinen
				Fettdepots unseres Gesichtes. Sowohl das Unterhautfett als auch
				spezielle Fettdepots sorgen für eine sogenannte Weichzeichnung
				des Gesichtes. Sind diese Fettdepots reduziert, so wirken
				Gesichter oft kantig, hart, grob.
			</p>
			<p>
				Spezielle Volumenfiller dienen der Wiederherstellung eines
				sanfteren, weicheren Gesichtes. Spezielle, großvolumige
				Hyaluronsäurepartikel wurden gezielt für diesen Volumenaufbau
				entwickelt
			</p>
			<p>
				Bei dem einbringen dieser Stoffe werden keine spitzen sondern
				stumpfe Kanülen verwendet. Eine stumpfe Kanülenspitze
				durchschneidet beim Vorschieben kein Gewebe sondern drängt es
				unblutig auseinander. Eine sehr Gewebe schonende Technik. Damit
				wird der Filler sehr elegant in die bedeutenden Zonen des
				Gesichtes eingebracht und das Gesicht so gestaltet wie SIE es
				wünschen.
			</p>

			<Questions title="Volumenfiller" prefix="um" />
		</Layout>
	);
};

export default Volumenfiller;
